/* You can add global styles to this file, and also import other style files */

@import './tt-material-template';
@import './basics.scss';
@import './fonts.scss';
@import './overlay.scss';

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
mat-card {
  border-top: 3px solid $tt-green;
}

.cach-buster-for-cors-issue {
  font-size: 16px;
}
