@import '@angular/cdk/overlay-prebuilt.css';

.overlay-backdrop {
  background-color: rgba(0, 0, 0, 0.8);
}

.overlay-panel {
  background-color: #fff;
  overflow-y: scroll;
  border-radius: 4px;
}
