@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
/* TODO(mdc-migration): Remove all-legacy-component-typographies once all legacy components are migrated*/
@include mat.all-component-typographies();
/* TODO(mdc-migration): Remove legacy-core once all legacy components are migrated*/
@include mat.core();

$mat-ttpalette: (
  50: #e0efe9,
  100: #b3d7c8,
  200: #80bda3,
  300: #4da37e,
  400: #268f62,
  500: #008253,
  600: #00733f,
  700: #006837,
  800: #005e2f,
  900: #004b20,
  A100: #7fffa8,
  A200: #4cff85,
  A400: #19ff63,
  A700: #00fe52,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$tt-material-primary: mat.m2-define-palette($mat-ttpalette, 500, 300, 900);
$tt-material-accent: mat.m2-define-palette($mat-ttpalette, A200, A100, A400);
$tt-material-warn: mat.m2-define-palette(mat.$m2-red-palette);
$tt-material-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $tt-material-primary,
      accent: $tt-material-accent,
      warn: $tt-material-warn,
    ),
    typography: mat.m2-define-typography-config(),
    density: 0,
  )
);

$tt-background-color: #eeeeee;
$background: map-get($tt-material-theme, background);
$background: map_merge(
  $background,
  (
    background: $tt-background-color,
  )
);
$tt-material-theme: map_merge(
  $tt-material-theme,
  (
    background: $background,
  )
);
/* TODO(mdc-migration): Remove all-legacy-component-themes once all legacy components are migrated*/
@include mat.all-component-themes($tt-material-theme);
